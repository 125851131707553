// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ReduxLoginProvider = require("./ReduxLoginProvider");

function useLogin(prim) {
  return ReduxLoginProvider.useReduxLogin();
}

function useLogout(prim) {
  return ReduxLoginProvider.useReduxLogout();
}

exports.useLogin = useLogin;
exports.useLogout = useLogout;
/* ./ReduxLoginProvider Not a pure module */
