import * as React from 'react'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { useSystemMessages } from '../reason/systemMessages/SystemMessages.bs'
import TerminatedAccountOverlay from '../reason/common/TerminatedAccountOverlay/TerminatedAccountOverlay.bs'
import ClosedChapterOverlay from '../reason/common/ClosedChapterOverlay/ClosedChapterOverlay.bs'

const checkAuth = ({
  asPath,
  isLoggedIn,
  initializing,
  push,
  requiredUserRole,
  userRole,
  redirectPath,
}) => {
  if (!initializing) {
    if (!isLoggedIn) {
      push({
        pathname: '/login',
        query: asPath === '/' ? undefined : { next: asPath },
      })
    }

    if (requiredUserRole && requiredUserRole !== userRole) {
      switch (userRole) {
        case 'agent':
          if (redirectPath) {
            push({ pathname: redirectPath })
          } else {
            push({ pathname: '/' })
          }
          break

        case 'client':
          if (redirectPath) {
            push({ pathname: redirectPath })
          } else {
            push({ pathname: '/client' })
          }
          break

        default:
      }
    }
  }
}

export function requireAuthentication(WrappedComponent, requiredUserRole, redirectPath) {
  function AuthenticatedComponent(props) {
    const { initializing, isLoggedIn, user } = props
    const userRole = user && user.role
    const router = useRouter()

    React.useEffect(() => {
      checkAuth({
        initializing,
        asPath: router.asPath,
        isLoggedIn,
        requiredUserRole,
        userRole,
        push: router.push,
        redirectPath,
      })
    }, [initializing, router.query, isLoggedIn, userRole, router.asPath, router.push])

    return isLoggedIn && (!requiredUserRole || requiredUserRole === userRole) ? (
      <>
        {userRole === 'agent' && (
          <>
            <TerminatedAccountOverlay pathname={router.pathname} />
            <ClosedChapterOverlay />
          </>
        )}
        <WrappedComponent {...props} />
      </>
    ) : null
  }

  const mapStateToProps = state => state.session

  return connect(mapStateToProps, null)(AuthenticatedComponent)
}

export const requireAuthenticatedAgent = WrappedComponent =>
  requireAuthentication(WrappedComponent, 'agent')

export const requireAuthenticatedClient = WrappedComponent =>
  requireAuthentication(WrappedComponent, 'client')

export function requireUnauthenticated(WrappedComponent) {
  function UnauthenticatedComponent(props) {
    const { push } = useRouter()
    const { initializing, isLoggedIn } = props
    const { addMessageJs: addMessage } = useSystemMessages()

    // Keep track of the login status right after init
    const [isLoggedInAfterInit, setLoggedInAfterInit] = React.useState(null)

    React.useEffect(() => {
      if (!initializing && isLoggedInAfterInit === null) {
        setLoggedInAfterInit(isLoggedIn)
      }
    }, [initializing, isLoggedIn, isLoggedInAfterInit])

    // If we were logged in when we initialized we'll ensure to guard against use of unauthed pages.
    // We need this little dance to make sure the message isn't added when logging in.
    React.useEffect(() => {
      if (isLoggedInAfterInit) {
        push('/')
        addMessage('You need to be logged out to view this page')
      }
    }, [isLoggedInAfterInit, push, addMessage])

    return isLoggedInAfterInit ? null : <WrappedComponent {...props} />
  }

  const mapStateToProps = state => state.session

  return connect(mapStateToProps)(UnauthenticatedComponent)
}
