// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Types__User = require("../../user/Types__User.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

var Chapter = {};

var closedChapters = {
  hd: 101,
  tl: {
    hd: 102,
    tl: /* [] */0
  }
};

function isChapterClosed(chapterId) {
  return Core__List.some(closedChapters, (function (x) {
                return x === chapterId;
              }));
}

function make(user, joinedAt) {
  return {
          user: user,
          joinedAt: joinedAt
        };
}

var ppx_printed_query = "fragment Types__Chapter__member on User   {\n..." + Types__User.Fragment.User.name + "   \nregisteredAt  \n}\n" + Types__User.Fragment.User.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var value$2 = Js_dict.get(value$1, "registeredAt");
  var field_registeredAt = value$2 !== undefined && !(value$2 == null) ? value$2 : undefined;
  return {
          user: Curry._1(Types__User.Fragment.User.parse, value$1),
          registeredAt: field_registeredAt
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Types__Chapter__member = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "Types__Chapter__member",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function decode(obj) {
  var partial_arg = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("createdAt was None", GraphQLConverter.Helpers.decodeFloatDate, obj.registeredAt));
  var partial_arg$1 = GraphQLConverter.Helpers.ok(obj.user);
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(make)(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var Member = {
  make: make,
  Types__Chapter__member: Types__Chapter__member,
  Fragment: undefined,
  decode: decode
};

exports.Chapter = Chapter;
exports.closedChapters = closedChapters;
exports.isChapterClosed = isChapterClosed;
exports.Member = Member;
/* Types__User Not a pure module */
