// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Next = require("../../next/Next.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var EventLogs = require("../../EventLogs.bs.js");
var ViewStack = require("../ViewStack.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var View__Jsx3 = require("../../../uikit/reason/helpers/View__Jsx3.bs.js");
var CurrentUser = require("../CurrentUser/CurrentUser.bs.js");
var Next__Atoms = require("../../next/Next__Atoms.bs.js");
var Dialog__Jsx3 = require("../../../uikit/reason/molecules/Dialog/Dialog__Jsx3.bs.js");
var Buttons__Jsx3 = require("../../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ReduxLoginProvider = require("../ReduxLoginProvider.bs.js");

function TerminatedAccountOverlay$ForceActivateDialog(props) {
  var logout = props.logout;
  return JsxRuntime.jsxs(Dialog__Jsx3.make, {
              title: "Your Subscription is Inactive",
              isOpen: true,
              ariaLabel: "Your subscription is inactive",
              onDismiss: (function (prim) {
                  
                }),
              showCloseButton: false,
              children: [
                JsxRuntime.jsxs(Text__Jsx3.make, {
                      lineHeight: {
                        NAME: "float",
                        VAL: 1.5
                      },
                      children: [
                        "You no longer have an active TAN subscription. Please go to your billing settings if you wish to reactivate it.",
                        JsxRuntime.jsx("br", {}),
                        JsxRuntime.jsx("br", {}),
                        "If you have another account that is active you can log out and use that one instead."
                      ]
                    }),
                JsxRuntime.jsx(View__Jsx3.make, {
                      mt: "md"
                    }),
                JsxRuntime.jsxs(ViewStack.make, {
                      justifyContent: "flexEnd",
                      flexDirection: "row",
                      children: [
                        JsxRuntime.jsx(Buttons__Jsx3.SecondaryButton.make, {
                              onClick: (function (param) {
                                  Curry._1(logout, undefined);
                                }),
                              children: "Log Out"
                            }),
                        JsxRuntime.jsx(Next__Atoms.PrimaryButton.make, {
                              href: "/subscribe",
                              children: "Reactivate Your Account"
                            })
                      ]
                    })
              ]
            });
}

var ForceActivateDialog = {
  make: TerminatedAccountOverlay$ForceActivateDialog
};

function TerminatedAccountOverlay(props) {
  var router = Next.useRouter();
  var pathname = Next.Router.pathname(router);
  var currentUser = CurrentUser.useCurrentSession();
  var logout = ReduxLoginProvider.useLogout();
  var recordEvent = EventLogs.useRecordEventByCurrentUser();
  var isOnAccountSettingsPage = Next.Router.asPath(router).startsWith("/account/billing");
  var isPageAllowed = isOnAccountSettingsPage || pathname === "/subscribe";
  if (typeof currentUser !== "object" || !(currentUser.TAG === "Agent" && currentUser._0.terminatedAccount && !isPageAllowed)) {
    return null;
  } else {
    return JsxRuntime.jsx(TerminatedAccountOverlay$ForceActivateDialog, {
                logout: (function (param) {
                    Curry._3(recordEvent, undefined, "Logout", undefined);
                    Curry._1(logout, undefined);
                  })
              });
  }
}

var make = TerminatedAccountOverlay;

var $$default = TerminatedAccountOverlay;

exports.ForceActivateDialog = ForceActivateDialog;
exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* Next Not a pure module */
