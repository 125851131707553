import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import { login, logout } from '../../actions/loginActions'

export const useReduxLogin = () => {
  const dispatch = useDispatch()
  return bindActionCreators(login, dispatch)
}

export const useReduxLogout = () => {
  const dispatch = useDispatch()
  return bindActionCreators(logout, dispatch)
}
