import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import { maxWidth } from 'styled-system'

import {
  DialogOverlay as BaseDialogOverlay,
  DialogContent as BaseDialogContent,
} from '@reach/dialog'
import '@reach/dialog/styles.css'

// Hack to correct aria label...
// eslint-disable-next-line
let HackBaseContent = React.forwardRef(({ ariaLabel, maxWidth, ...props }, ref) =>
  React.createElement(BaseDialogContent, { 'aria-label': ariaLabel, ...props, ref })
)

export const UnstyledContent = styled(HackBaseContent)`
  &[data-reach-dialog-content] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
  }
`

export const UnstyledOverlay = styled(BaseDialogOverlay)`
  &[data-reach-dialog-overlay] {
    box-sizing: border-box;
    z-index: 9999999;
  }
`

export { HackBaseContent as BaseContent, BaseDialogOverlay as BaseOverlay }

export const DialogContent = styled(HackBaseContent)`
  &[data-reach-dialog-content] {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    ${maxWidth}
    padding: 20px;
    @media screen and (min-width: 400px) {
      border-radius: 5px;
    }
  }
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-left: -20px;
  margin-top: -21px;
  margin-bottom: 20px;
  padding: 20px;
  tex-align: center;
  background-color: ${props => props.theme.colors.stTropaz};
  box-sizing: content-box;
  @media screen and (min-width: 400px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`

export const DialogOverlay = styled(BaseDialogOverlay)`
  &[data-reach-dialog-overlay] {
    z-index: 2;
    background: rgba(27, 53, 73, 0.8);
    @media screen and (min-width: 700px) {
      background: rgba(27, 53, 73, 0.5);
      backdrop-filter: blur(3px) brightness(80%) saturate(30%);
    }
  }
`

export const AnimatedOverlay = animated(DialogOverlay)
export const AnimatedContent = animated(DialogContent)
export const AnimatedUnstyledContent = animated(UnstyledContent)

export const AnimatedBaseOverlay = animated(BaseDialogOverlay)
export const AnimatedBaseContent = animated(HackBaseContent)
