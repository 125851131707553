// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ReactUseIntercom = require("./ReactUseIntercom");

function useIntercom(prim) {
  return ReactUseIntercom.useIntercom();
}

exports.useIntercom = useIntercom;
/* ./ReactUseIntercom Not a pure module */
